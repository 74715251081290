import React, { useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';

import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  MARGIN,
  PADDING,
  POSITION
} from '@latitude/core/utils/constants';
import { Heading2 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { FeatureTiles } from '@latitude/feature-tiles';
import { ImportantInformation } from '@latitude/important-information';
import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { HeroText } from '@latitude/hero/Hero.styles';
import { BrandedCallout } from '@latitude/callout';
import { Tel } from '@latitude/tel';
import { FeaturesSlider } from '@latitude/features-slider';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { HorizontalRule } from '@latitude/horizontal-rule';

import Layout from '@/components/layout';
import PageData from '@/data/pages/calculators-and-tools/car-loan-repayment-calculator.json';
import CarLoanCalculator from '@/components/LoanCalculator/CarLoanCalculator1';
import HowToApplySection from '@/components/HowToApplySection/HowToApplySection1';
import Button from '@/components/Button/Button';

import withStickyState from '../../hoc/withStickyState';
import { CL_TEL } from '../../utils/constants';
import { SOFT_QUOTE_LOANS_URL } from '../../utils/softQuoteUtil';
import heroImage from '../../images/pl-car-loan-calculator_hero-image-nz_417x395.webp';
import { PageContext } from '../../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const CLCalculatorPage = props => {
  const featureSliderItems = [
    {
      icon: 'icon-snap-fingers-branded',
      title: 'Un-complicated loans',
      text:
        'Get an indicative Quote in 2 minutes, Apply in under 7 minutes. Once approved and you accept your contract, funds in your account in less than 24 hours.'
    },
    {
      icon: 'icon-slinky',
      title: 'Flexible options',
      text:
        'At Gem, you can choose to apply for a Fixed or Variable loan, along with an option to set up monthly, fortnightly or weekly repayments.'
    },
    {
      icon: 'icon-extras-branded',
      title: 'Redraw extra funds',
      text:
        "Get flexible access to funds when you need them, when you're ahead on repayments with a Variable Rate Personal Loan."
    }
  ];

   /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    featureSliderData: [
      {
        heading: 'Why choose a Gem Loan?',
        featureCards: featureSliderItems
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData: heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */
  return (
    <Layout location={props.location} hasStickyNav>
      <main className="navigation-spacer navigation-spacer--hasStickyNav car-loan-calculator-page">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Metadata
            title={`${PageData.title}`}
            description={PageData.description}
            canonical={PageData.path}
          />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <Section
              css={`
                background-color: #0555c8;
                padding: 0;

                @media (max-width: ${BREAKPOINT.MD}) {
                  && > .container.section__content {
                    padding: 0;
                  }
                }
              `}
            >
              <Hero
                css={`
                  && {
                    color: #000;
                  }

                  h1 {
                    color: #fff;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                  }

                  p {
                    color: #fff;
                    font-size: 19px;
                    font-weight: normal;
                  }

                  svg {
                    min-height: 45px;
                    min-width: 45ßpx;
                  }

                  .HeroContent {
                    padding: 64px 0px 117px;
                    line-height: normal;
                  }

                  [class^='Herostyles__HeroImage-'] img {
                    height: 90%;
                  }

                  @media (max-width: ${BREAKPOINT.MD}) {
                    h1,
                    .text-left,
                    p,
                    .col-12 {
                      color: #000 !important;
                    }

                    p {
                      font-size: 20px;
                    }

                    h1 {
                      font-size: 35px;
                      margin: 5px 0;
                      white-space: normal;
                    }

                    .HeroContent {
                      padding: 10px;
                    }

                    .text-center {
                      color: #000 !important;
                    }

                    [class^='Herostyles__HeroImage-'] {
                      margin-top: 25px;
                    }
                  }
                `}
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-end;
                      }
                    `}
                  >
                    <img
                      src={heroImage}
                      alt=""
                      css={`
                        height: 250px;
                        width: auto;
                        @media (min-width: ${BREAKPOINT.MD}) {
                          height: 300px;
                        }
                        @media (min-width: ${BREAKPOINT.LG}) {
                          height: 382px;
                        }
                      `}
                    />
                  </div>
                }
                className="page-banner-lfs--bg"
              >
                {
                  <div
                    id="hero"
                    css={`
                  && {
                    padding: 0 15px;
                    color: #fff:

                    @media (min-width: ${BREAKPOINT.LG}) {
                      padding: 0;
                    }
                  }
                `}
                  >
                    <h1>
                      Car Loan <br /> Repayment Calculator
                    </h1>
                    <HeroText
                      css={`
                        && {
                          color: #fff;
                        }
                      `}
                    >
                      Use our handy calculator to figure out what your Car Loan
                      repayments might look like.
                    </HeroText>
                  </div>
                }
              </Hero>
            </Section>
          )}
          <div className="d-lg-none d-xl-none">
            <StickyCta
              href={SOFT_QUOTE_LOANS_URL}
              trackId="sticky-cta--get-my-rate"
              text="Get rate estimate"
              onClick={() => {
                // force link to open in the same tab
                if (typeof window !== 'undefined')
                  window.location = SOFT_QUOTE_LOANS_URL;
              }}
            />
          </div>
          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:10;"
          >
            <StickyNavigation
              items={PageData.nav}
              isSticky={props.isNavSticky}
              onStickyNavStateChange={props.handleStickyNavStateChange}
              phoneNumber={PageData.content.phoneNumber}
              ctaHref={SOFT_QUOTE_LOANS_URL}
              ctaText="Get rate estimate"
              offsetElem="[data-sticky-navigation-offset]"
              trackId="sticky-nav-get-my-rate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                label: 'Get rate estimate',
                location: 'Sticky Navigation'
              }}
              trackProductId={['MLNZLF-WEB']}
            />
          </div>

          <Box
            id={PageData.nav[0].anchor}
            backgroundColor={COLOR.BLUE_SKY}
            position={POSITION.RELATIVE}
          >
            <Box padding={`${PADDING.P48} 15px 0 15px`}>
              <Heading2 color={COLOR.BLACK} align={ALIGN.CENTER}>
                Calculate your repayments
              </Heading2>
            </Box>
            <Box isResponsive margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
              <CarLoanCalculator
                applyUrl={SOFT_QUOTE_LOANS_URL}
                applyCTATrackProductId={['MLNZLF-WEB']}
              />
            </Box>
          </Box>

          {state?.featureSliderData?.[0] && (
            <FeaturesSlider
              backgroundColor={COLOR.GREY6}
              heading={state.featureSliderData[0].heading}
              subheading={state.featureSliderData[0].description}
              data={state.featureSliderData[0].featureCards}
              className="why-choose pt-4 pt-md-5"
            />
          )}
          <HorizontalRule />

          <Box id={PageData.nav[1].anchor}>
            <BrandedCallout
              moreVerticalPadding
              hasConfettiBackground
              confettiBackground={PageData.content.callOut.bgImage}
              confettiBackgroundColor={COLOR.WHITE}
              confettiBackgroundRepeat="repeat"
              confettiBackgroundPos="center 35%"
              heading={PageData.content.callOut.title}
              line1={ReactHTMLParser(PageData.content.callOut.content)}
              cta={
                <Box>
                  <Button
                    secondary
                    href={PageData.content.callOut.ctaUrl}
                    trackId="callout-find-out-more"
                    trackEventData={{
                      category: 'button-link',
                      action: 'internal-link'
                    }}
                    trackProductId={['MLNZLF-WEB']}
                  >
                    Let&apos;s Go!
                  </Button>
                </Box>
              }
            />
          </Box>
          <HowToApplySection />
          <FeatureTiles
            id={PageData.nav[3].anchor}
            heading={PageData.content.calcTools.title}
            tiles={PageData.content.calcTools.tiles}
          />
          <BrandedCallout
            line1={
              <React.Fragment>
                <strong>
                  Call our loan specialists on{' '}
                  <Tel no={CL_TEL} color={COLOR.WHITE} />
                </strong>
              </React.Fragment>
            }
            line2={
              <React.Fragment>
                <span>Mon to Fri 9:00am - 6:30pm.</span>
              </React.Fragment>
            }
            cta={
              <Box width="210px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={SOFT_QUOTE_LOANS_URL}
                  trackId="callout-get-my-rate"
                  className="w-auto"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: "We're here to help"
                  }}
                  trackProductId={['MLNZLF-WEB']}
                  style={{ marginTop: 24 }}
                >
                  Get rate estimate
                </Link>
              </Box>
            }
          />
          <ImportantInformation
            data={require('../../data/pages/calculators-and-tools/car-loan-repayment-calculator.json')}
            sectionOneColummClasses="col-10 offset-1"
          />
        </Box>
      </main>
    </Layout>
  );
}

export default withStickyState(CLCalculatorPage);
